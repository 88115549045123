import React from 'react';
import Jumbotron from '../../components/Jumbotron';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../../components/Layout';
import CatalogueRoll from '../../components/CatalogueRoll';

import ContentContainer from '../../components/Layouts/ContentContainer';
import Section from '../../components/Layouts/ContentLayout/Section';

const BrandsIndexPageTemplate = ({ jumbotron, cards }) => {
  return (
    <div>
      <Jumbotron jumbotron={jumbotron}></Jumbotron>
      <ContentContainer>
        <div className="columns">
          <Section
            title="Brands"
            description="See list of our product and services"
          ></Section>
        </div>
        <CatalogueRoll cards={cards}></CatalogueRoll>
      </ContentContainer>
    </div>
  );
};

const BrandsIndexPage = ({ data }) => {
  const { frontmatter } = data.settings;
  const { nodes } = data.cardsData;
  const cards = nodes.map((node) => {
    const frontmatter = node.frontmatter;
    return {
      title: frontmatter.name,
      subtitle: 'Brand',
      image: frontmatter.featuredimage,
      link: node.fields.slug,
    };
  });

  return (
    <Layout>
      <BrandsIndexPageTemplate
        jumbotron={frontmatter.jumbotron}
        cards={cards}
      ></BrandsIndexPageTemplate>
    </Layout>
  );
};

const pageQuery = graphql`
  query BrandsIndexPageQuery {
    settings: markdownRemark(
      frontmatter: { settingKey: { eq: "brand-index-settings" } }
    ) {
      frontmatter {
        title
        jumbotron {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          heading
          subheading
        }
      }
    }
    cardsData: allMarkdownRemark(
      filter: { frontmatter: { queryKey: { eq: "brands" } } }
    ) {
      nodes {
        frontmatter {
          featuredimage {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
          name
          parent
        }
        fields {
          slug
        }
      }
    }
  }
`;

export default () => {
  return (
    <StaticQuery
      query={pageQuery}
      render={(data) => <BrandsIndexPage data={data}></BrandsIndexPage>}
    ></StaticQuery>
  );
};
